/* eslint-disable import/no-cycle */
// @flow

import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { Action, Column } from './Types';
import { MobileTable, DesktopTable } from '.';

type Props = {
  columns: Column[],
  items: any[],
  actions: Action[],
  loading: boolean,
};

export default function Table({
  items,
  columns,
  actions = [],
  loading,
}: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return matches ? (
    <DesktopTable
      columns={columns}
      actions={actions}
      items={items}
      loading={loading}
    />
  ) : (
    <MobileTable
      columns={columns}
      actions={actions}
      items={items}
      loading={loading}
    />
  );
}
