/* eslint-disable no-nested-ternary */
// @flow
import * as React from 'react';

import { Document, Image, Page, StyleSheet } from '@react-pdf/renderer';

import { DRA_FIELDS } from 'Constants';
import moment from 'moment';
import Header from './Header';
import TableRow2Cell from './TableRow2Cell';

import TableRow2ImageBase64 from './TableRowImageBase64';
import type { Dra } from './Types';
import P from './P';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
  },
  logo: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '214px',
    height: '92px',
  },

  imageWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  image: {
    width: '230px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  imageTitle: {
    fontSize: 8,
  },
});

type Props = {
  data: Dra,
};

// Create Document Component
const DraDocument = ({ data }: Props): React.Node => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image source="/Venidi.png" style={styles.logo} />

      <Header variant="H1" text="Venidi - Dynamic Risk Assessment" />

      <TableRow2Cell
        title="Employee"
        value={`${data.fillBy.firstName} ${data.fillBy.lastName}`}
      />

      <TableRow2Cell title="Address" value={data.address} />

      <TableRow2Cell
        title="Date"
        value={moment(data.date).format('DD-MM-YYYY')}
      />

      <Header variant="H2" text="Activity List" />

      {data.accessAndEgress && (
        <TableRow2Cell title={DRA_FIELDS.accessAndEgress} value="" />
      )}

      {data.loadingUnloading && (
        <TableRow2Cell title={DRA_FIELDS.loadingUnloading} value="" />
      )}

      {data.manualHandling && (
        <TableRow2Cell title={DRA_FIELDS.manualHandling} value="" />
      )}

      {data.workingAtHeight && (
        <TableRow2Cell title={DRA_FIELDS.workingAtHeight} value="" />
      )}

      {data.liftingAndLowering && (
        <TableRow2Cell title={DRA_FIELDS.liftingAndLowering} value="" />
      )}

      {data.workingWithCableOrAntenna && (
        <TableRow2Cell title={DRA_FIELDS.workingWithCableOrAntenna} value="" />
      )}

      {data.useOfPowerTools && (
        <TableRow2Cell title={DRA_FIELDS.useOfPowerTools} value="" />
      )}

      {data.workingWithElectricity && (
        <TableRow2Cell title={DRA_FIELDS.workingWithElectricity} value="" />
      )}

      {data.accessingFalseFloorsOrCeilings && (
        <TableRow2Cell
          title={DRA_FIELDS.accessingFalseFloorsOrCeilings}
          value=""
        />
      )}

      {data.hotWorks && <TableRow2Cell title={DRA_FIELDS.hotWorks} value="" />}

      {data.confinedSpaceWorking && (
        <TableRow2Cell title={DRA_FIELDS.confinedSpaceWorking} value="" />
      )}

      {data.loneWorking && (
        <TableRow2Cell title={DRA_FIELDS.loneWorking} value="" />
      )}

      {data.surveying && (
        <TableRow2Cell title={DRA_FIELDS.surveying} value="" />
      )}

      {data.testingAndCommissioning && (
        <TableRow2Cell title={DRA_FIELDS.testingAndCommissioning} value="" />
      )}

      {data.interactionWithOthers && (
        <TableRow2Cell title={DRA_FIELDS.interactionWithOthers} value="" />
      )}

      {data.electricalTest && (
        <TableRow2Cell title={DRA_FIELDS.electricalTest} value="" />
      )}

      {data.undergroundUtilityServices && (
        <TableRow2Cell title={DRA_FIELDS.undergroundUtilityServices} value="" />
      )}

      {data.permitToDig && (
        <TableRow2Cell title={DRA_FIELDS.permitToDig} value="" />
      )}

      {data.excavation && (
        <TableRow2Cell title={DRA_FIELDS.excavation} value="" />
      )}

      {data.stockpiles && (
        <TableRow2Cell title={DRA_FIELDS.stockpiles} value="" />
      )}

      {data.reinstatement && (
        <TableRow2Cell title={DRA_FIELDS.reinstatement} value="" />
      )}

      {data.qualityChecks && (
        <TableRow2Cell title={DRA_FIELDS.qualityChecks} value="" />
      )}

      {data.siteLeftSafe && (
        <TableRow2Cell title={DRA_FIELDS.siteLeftSafe} value="" />
      )}

      <Header variant="H2" text="" />

      <TableRow2Cell
        title={DRA_FIELDS.haveYouReadAsbestosReport}
        value={data.haveYouReadAsbestosReport ? 'Yes' : 'No'}
      />

      <TableRow2Cell
        title={DRA_FIELDS.haveYouReceivedADailyActivitySiteBrief}
        value={data.haveYouReceivedADailyActivitySiteBrief ? 'Yes' : 'No'}
      />

      <TableRow2Cell
        title={DRA_FIELDS.isClimbingRequired}
        value={data.isClimbingRequired ? 'Yes' : 'No'}
      />

      <TableRow2Cell
        title={DRA_FIELDS.isCivilsRequired}
        value={data.isCivilsRequired ? 'Yes' : 'No'}
      />

      <TableRow2Cell
        title={DRA_FIELDS.preUseInspection}
        value={data.preUseInspection ? 'Yes' : 'No'}
      />

      <TableRow2Cell title={DRA_FIELDS.rating} value={data.rating} />

      <Header variant="H2" text="Signatories" />

      <TableRow2ImageBase64 image={data.sign} />

      <P
        text="I confirm I have read and understood the supplied Method Statement and Risk Assessment which are appropriate for the task(s). Any associated risks or hazards have been appropriately assessed for the working activities selected from within the DRA."
        variant="note"
      />
    </Page>
  </Document>
);

export default DraDocument;
