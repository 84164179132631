// @flow

import { useUserMe } from 'Entities/User';
import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }: { children: any }): React.Node => {
  const [, refetch, clear] = useUserMe({ manual: true });

  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    refetch();
    setTimeout(() => navigate('/'), 500);
  };

  // call this function to sign out logged in user
  const logout = async () => {
    clear();
    setUser(null);
    document.cookie = 'jwt_hp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    navigate('/login', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
