// @flow
import * as React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

import type { Action, Column } from './Types';

export type Props = {|
  columns: Column[],
  actions: Action[],
|};

export default function TableHeader({ columns, actions }: Props) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.title.toLowerCase().replace(/ /g, '-')}>
            {column.title}
          </TableCell>
        ))}

        {actions.length > 0 && (
          <TableCell sx={{ width: `${48 * (actions.length + 1)}px` }}>
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
