/* eslint-disable func-names */
/* eslint-disable no-console */
// @flow

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
// $FlowFixMe
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { Controller, useForm } from 'react-hook-form';

// $FlowFixMe
import * as yup from 'yup';

// $FlowFixMe
import { yupResolver } from '@hookform/resolvers/yup';
import FieldContainer from 'Components/FieldContainer';
import SignatureFormField from 'Components/SignatureFormField';
import useLocation from 'Hooks/useLocation';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    address: yup.string().required(),
    accessAndEgress: yup.boolean().required(),
    workingWithCableOrAntenna: yup.boolean().required(),
    loadingUnloading: yup.boolean().required(),
    manualHandling: yup.boolean().required(),
    workingAtHeight: yup.boolean().required(),
    accessingFalseFloorsOrCeilings: yup.boolean().required(),
    liftingAndLowering: yup.boolean().required(),
    testingAndCommissioning: yup.boolean().required(),
    useOfPowerTools: yup.boolean().required(),
    undergroundUtilityServices: yup.boolean().required(),
    workingWithElectricity: yup.boolean().required(),
    hotWorks: yup.boolean().required(),
    confinedSpaceWorking: yup.boolean().required(),
    loneWorking: yup.boolean().required(),
    surveying: yup.boolean().required(),
    interactionWithOthers: yup.boolean().required(),
    electricalTest: yup.boolean().required(),
    permitToDig: yup.boolean().required(),
    stockpiles: yup.boolean().required(),
    reinstatement: yup.boolean().required(),
    qualityChecks: yup.boolean().required(),
    siteLeftSafe: yup.boolean().required(),
    excavation: yup.boolean().required(),
    haveYouReadAsbestosReport: yup.boolean().required(),
    haveYouReceivedADailyActivitySiteBrief: yup.boolean().required(),
    isClimbingRequired: yup.boolean().required(),
    isCivilsRequired: yup.boolean().required(),
    rating: yup.string(),
    preUseInspection: yup.boolean().required(),
    sign: yup.mixed().nullable().required(),
  })
  .required();

type Props = {|
  onSubmit: Function,
  loading: boolean,
|};

const steps = [{ label: 'MENTAL HEALTH' }, { label: 'DRA' }];

const defaultValues = {
  address: null,
  accessAndEgress: false,
  workingWithCableOrAntenna: false,
  loadingUnloading: false,
  accessingFalseFloorsOrCeilings: false,
  manualHandling: false,
  workingAtHeight: false,
  liftingAndLowering: false,
  useOfPowerTools: false,
  undergroundUtilityServices: false,
  workingWithElectricity: false,
  hotWorks: false,
  testingAndCommissioning: false,
  confinedSpaceWorking: false,
  loneWorking: false,
  surveying: false,
  interactionWithOthers: false,
  electricalTest: false,
  permitToDig: false,
  stockpiles: false,
  reinstatement: false,
  qualityChecks: false,
  excavation: false,
  siteLeftSafe: false,
  haveYouReadAsbestosReport: false,
  haveYouReceivedADailyActivitySiteBrief: false,
  isClimbingRequired: false,
  isCivilsRequired: false,
  rating: null,
  preUseInspection: false,
  sign: null,
};

const DraForm = (props: Props): React.Node => {
  const { onSubmit, loading } = props;
  const submitRef = React.useRef();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();

  const location = useLocation();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (location) {
      setValue('address', location);
    }
  }, [location, setValue]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);

  const maxSteps = steps.length;

  const handleNext = async () => {
    if (activeStep === 1) {
      submitRef.current.click();

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/dra');

      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setHasError(false);
  };

  const handleOnSubmit = async (data) => {
    onSubmit(data);
  };

  const handleOnError = () => setHasError(true);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert severity="error">
          There is a problem. Please, check all required fields.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 50,
            bgcolor: 'background.default',
            zIndex: 1,
            paddingTop: '5px',
            position: 'fixed',
            top: 56,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>

          <Typography sx={{ fontSize: '10px' }}>
            {`Page ${activeStep + 1} / ${maxSteps}`}
          </Typography>
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '60px  10px 50px 10px ',
          }}
        >
          <Box sx={{ display: activeStep === 0 ? 'auto' : 'none' }}>
            <Typography variant="h6">DONT SUFFER IN SILENCE</Typography>

            <Typography variant="body" component="p">
              We have resources available within Venidi with our Mental Health
              First Aiders being:
            </Typography>

            <Typography variant="body" component="p">
              Veselin Dichev - <Link href="tel:07704496734">07704496734</Link>
            </Typography>

            <Typography variant="body" component="p">
              Nikolay Dichev - <Link href="tel:07708042761">07708042761</Link>
            </Typography>

            <Typography variant="body" component="p">
              If you would prefer to seek help outside the business, the
              following links may be of some assistance:
              <ul>
                <li>
                  <Link href="www.headstogether.org.uk" target="_blan">
                    Heads Together
                  </Link>
                </li>

                <li>
                  <Link href="www.mind.org.uk" target="_blan">
                    Mind
                  </Link>
                </li>

                <li>
                  <Link href="www.mentalhealth-uk.org" target="_blan">
                    Mental Health UK
                  </Link>
                </li>

                <li>
                  <Link href="www.turning-point.co.uk" target="_blan">
                    Turning Point
                  </Link>
                </li>

                <li>
                  <Link href="www.samaritans.org" target="_blan">
                    Samaritans
                  </Link>
                </li>

                <li>
                  <Link href="www.rethink.org" target="_blan">
                    Re Think Mental Health
                  </Link>
                </li>
              </ul>
            </Typography>

            <Typography variant="h6">PPE</Typography>

            <Typography variant="body" component="p">
              {[
                `If you require more stock of PPE please contact Veselin Dichev - `,
                <Link href="tel:07704496734">07704496734</Link>,
                ` (`,
                <Link href="mailto:veselin.dichev@venidi.co.uk">
                  veselin.dichev@venidi.co.uk
                </Link>,
                `) with your requirements.`,
              ]}
            </Typography>

            <Typography variant="h6">KEY POINTS:</Typography>

            <Typography variant="body" component="p">
              <ul>
                <li>Ensure you complete your Venidi DRA on arrival to site</li>

                <li>
                  If at any point the control measures detailed within the Risk
                </li>

                <li>Activity Assessment</li>

                <li>Select a project assigned to you this week:</li>
              </ul>
            </Typography>
          </Box>

          <Box sx={{ display: activeStep === 1 ? 'auto' : 'none' }}>
            <FieldContainer
              htmlFor="address"
              label="Address"
              error={errors.address}
            >
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField {...field} variant="outlined" fullWidth />
                )}
              />
            </FieldContainer>

            <FieldContainer
              label="Activity list"
              error={errors.accessAndEgress}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('accessAndEgress', val.target.checked)
                      }
                    />
                  }
                  label="Access & Egress"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('loadingUnloading', val.target.checked)
                      }
                    />
                  }
                  label="Loading/Unloading"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('manualHandling', val.target.checked)
                      }
                    />
                  }
                  label="Manual Handling"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('workingAtHeight', val.target.checked)
                      }
                    />
                  }
                  label="Working at Height (Ladders, Rooftops, Towers etc.)"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('liftingAndLowering', val.target.checked)
                      }
                    />
                  }
                  label="Lifting & Lowering"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue(
                          'workingWithCableOrAntenna',
                          val.target.checked,
                        )
                      }
                    />
                  }
                  label="Working with Cable/Antenna Installation"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('useOfPowerTools', val.target.checked)
                      }
                    />
                  }
                  label="Use of Power Tools"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('workingWithElectricity', val.target.checked)
                      }
                    />
                  }
                  label="Working with Electricity"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue(
                          'accessingFalseFloorsOrCeilings',
                          val.target.checked,
                        )
                      }
                    />
                  }
                  label="Accessing false floors/ceilings"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('hotWorks', val.target.checked)
                      }
                    />
                  }
                  label="Hot Works"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('confinedSpaceWorking', val.target.checked)
                      }
                    />
                  }
                  label="Confined Space Working"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('loneWorking', val.target.checked)
                      }
                    />
                  }
                  label="Lone Working"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('surveying', val.target.checked)
                      }
                    />
                  }
                  label="Surveying"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('testingAndCommissioning', val.target.checked)
                      }
                    />
                  }
                  label="Testing & Commissioning"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('interactionWithOthers', val.target.checked)
                      }
                    />
                  }
                  label="Interaction with others"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('electricalTest', val.target.checked)
                      }
                    />
                  }
                  label="Electrical Test"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue(
                          'undergroundUtilityServices',
                          val.target.checked,
                        )
                      }
                    />
                  }
                  label="Underground utility services"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('permitToDig', val.target.checked)
                      }
                    />
                  }
                  label="Permit to Dig"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('excavation', val.target.checked)
                      }
                    />
                  }
                  label="Excavation"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('stockpiles', val.target.checked)
                      }
                    />
                  }
                  label="Stockpiles"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('reinstatement', val.target.checked)
                      }
                    />
                  }
                  label="Reinstatement"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('qualityChecks', val.target.checked)
                      }
                    />
                  }
                  label="Quality Checks"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(val) =>
                        setValue('siteLeftSafe', val.target.checked)
                      }
                    />
                  }
                  label="Site Left Safe"
                />
              </FormGroup>
            </FieldContainer>

            <FieldContainer
              htmlFor="haveYouReadAsbestosReport"
              error={errors.haveYouReadAsbestosReport}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) =>
                        setValue(
                          'haveYouReadAsbestosReport',
                          val.target.checked,
                        )
                      }
                    />
                  }
                  label="Have you read the Asbestos report?"
                />
              </FormGroup>
            </FieldContainer>

            <FieldContainer
              htmlFor="haveYouReceivedADailyActivitySiteBrief"
              error={errors.haveYouReceivedADailyActivitySiteBrief}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) =>
                        setValue(
                          'haveYouReceivedADailyActivitySiteBrief',
                          val.target.checked,
                        )
                      }
                    />
                  }
                  label="Have you received a daily activity site brief?"
                />
              </FormGroup>
            </FieldContainer>

            <FieldContainer
              htmlFor="isClimbingRequired"
              error={errors.isClimbingRequired}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) =>
                        setValue('isClimbingRequired', val.target.checked)
                      }
                    />
                  }
                  label="Is climbing required"
                />
              </FormGroup>
            </FieldContainer>

            <FieldContainer
              htmlFor="isCivilsRequired"
              error={errors.isCivilsRequired}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) =>
                        setValue('isCivilsRequired', val.target.checked)
                      }
                    />
                  }
                  label="Is civils required"
                />
              </FormGroup>
            </FieldContainer>

            <FieldContainer
              htmlFor="rating"
              label="Select rating"
              error={errors.rating}
            >
              <Controller
                control={control}
                name="rating"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="A0"
                      control={<Radio />}
                      label="A0"
                    />

                    <FormControlLabel
                      value="A1"
                      control={<Radio />}
                      label="A1"
                    />

                    <FormControlLabel
                      value="A2"
                      control={<Radio />}
                      label="A2"
                    />

                    <FormControlLabel
                      value="A3"
                      control={<Radio />}
                      label="A3"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor="preUseInspection"
              error={errors.preUseInspection}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) =>
                        setValue('preUseInspection', val.target.checked)
                      }
                    />
                  }
                  label="Pre-Use Inspection (Has all PPE, tooling, ladders and lifting equipment had a visual pre use inspection by the user)"
                />
              </FormGroup>
            </FieldContainer>

            <SignatureFormField
              label="Signature"
              value={getValues('sign')}
              onChange={(val) => {
                setValue('sign', val);
                forceUpdate();
              }}
              error={errors.sign}
            />

            <Button type="submit" sx={{ display: 'none' }} ref={submitRef} />
          </Box>
        </Box>

        <MobileStepper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={loading}>
              {activeStep === maxSteps - 1 ? 'Finish' : 'Start DRA'}

              {activeStep < maxSteps - 1 && <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </form>
  );
};

export default DraForm;
