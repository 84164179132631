// @flow
import * as React from 'react';

import {
  Add,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Fab,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AA from 'Components/AA';
import FieldContainer from 'Components/FieldContainer';
import Pager from 'Components/Pager';
import Table from 'Components/Table';
import { ROLES } from 'Constants';
import { useDraList } from 'Entities/Dra';
import { useUserList } from 'Entities/User';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

function DraList(): React.Node {
  const [{ data, loading }, refetch] = useDraList();
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ data: users }] = useUserList();
  const navigate = useNavigate();
  const columns = [
    {
      key: 'id',
      title: '#',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: (val) => <Link to={`/dra/view/${val}`}>{val}</Link>,
    },
    {
      key: 'fillBy',
      title: 'User',
      component: (val) => `${val.firstName} ${val.lastName}`,
    },
    { key: 'address', title: 'Address' },
    {
      key: 'date',
      title: 'Date',
      component: (val) => {
        const date = new Date(val);

        return date.toLocaleString();
      },
    },
  ];

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user: null,
      date: null,
    },
  });

  const handlePageChange = (nextPage) => {
    const params = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams.entries()) {
      if (value !== 'undefined' && value !== null && value !== '') {
        params[key] = value;
      }
    }
    setSearchParams({ ...params, page: `${nextPage}` });
  };

  React.useEffect(() => {
    const params = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams.entries()) {
      if (value !== 'undefined' && value !== null && value !== '') {
        params[key] = value;
        if (key === 'user' && users) {
          const user = users.find((u) => u.id === parseInt(value, 10));

          setValue('user', user);
        }
        if (key === 'date') {
          setValue('date', moment(value));
        }
      }
    }
    if (Object.keys(params).length > 0) {
      refetch({ params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, users]);

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = async (submittedData) => {
    const { user, date } = submittedData;
    const params = {};

    if (user) {
      params.user = user.id;
    }
    if (date) {
      params.date = date.format();
    }

    setSearchParams({
      ...params,
    });
  };

  return (
    <>
      <Box sx={{ p: { xs: 1, sm: 3 } }}>
        <AA roles={[ROLES.Admin]}>
          <Paper sx={{ mb: 2 }}>
            <Box
              component="form"
              onSubmit={handleSubmit(handleOnSubmit)}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FieldContainer htmlFor="user" label="User" error={errors.user}>
                  <Controller
                    name="user"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        value={value}
                        options={users}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        isOptionEqualToValue={(option, val) =>
                          option.id === val.id
                        }
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(event, v) => onChange(v)}
                      />
                    )}
                  />
                </FieldContainer>

                <FieldContainer htmlFor="date" label="Date" error={errors.date}>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <MobileDatePicker
                        id="date-dialog"
                        inputFormat="MM/DD/YYYY"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: '300px' }} />
                        )}
                        {...rest}
                      />
                    )}
                  />
                </FieldContainer>

                <Box>
                  <Button type="submit">
                    <SearchIcon />
                  </Button>

                  <Button
                    type="button"
                    onClick={() => {
                      reset();
                      setSearchParams({});
                      refetch();
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </LocalizationProvider>
            </Box>
          </Paper>
        </AA>

        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Table
            columns={columns}
            items={data && data.result}
            loading={loading}
          />

          <Pager
            current={searchParams.has('page') ? searchParams.get('page') : 1}
            onChange={handlePageChange}
            total={data && data.meta && data.meta.totalPages}
          />
        </Paper>
      </Box>

      <Fab
        sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
        color="primary"
        onClick={() => navigate('/dra/create')}
      >
        <Tooltip title="Create a DRA">
          <Add />
        </Tooltip>
      </Fab>
    </>
  );
}

export default DraList;
