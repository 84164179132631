/* eslint-disable func-names */
/* eslint-disable no-console */
// @flow

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormControlLabel,
  InputBase,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
// $FlowFixMe
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

// $FlowFixMe
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// $FlowFixMe
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// $FlowFixMe
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import FieldContainer from 'Components/FieldContainer';

import { Controller, useForm } from 'react-hook-form';

import useLocation from 'Hooks/useLocation';

import { convertFileToBase64 } from 'Utils/image';

// $FlowFixMe
import * as yup from 'yup';

import moment from 'moment';

// $FlowFixMe
import { yupResolver } from '@hookform/resolvers/yup';
import SignatureFormField from 'Components/SignatureFormField';

const schema = yup
  .object({
    siteId: yup.string().required(),
    siteDevName: yup.string().required(),
    blockName: yup.string().required(),
    siteLocation: yup.string().required(),
    reportStartDate: yup.object().required(),
    items: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          operativeName: yup.string().required(),
          reIntatementDate: yup.object().required(),
          locationOfFireStopping: yup.mixed().nullable().required(),
          floorLevel: yup.string().required(),
          substrateType: yup.mixed().nullable().required(),
          substrateDepth: yup.number().required().positive().integer(),
          serviceType: yup.mixed().nullable().required(),
          holeDiameter: yup.number().required().positive().integer(),
          bundle: yup.number().required().positive().integer(),
          productTypeUsed: yup.mixed().nullable().required(),
          intendedProductResistanceTime: yup.string().required(),
          preReinstatmentImage: yup.mixed().nullable().required(),
          postReinstatmentImage: yup.mixed().nullable().required(),
        }),
      )
      .min(1),
    generalNotesAndComments: yup.string().nullable(),
    signatureImage: yup.mixed().nullable().required(),
  })
  .required();

type Props = {|
  onSubmit: Function,
|};

const steps = [
  { label: 'Title Page' },
  { label: 'Record of Installation' },
  { label: 'Sign off' },
];

const IMAGE_FIELDS = [
  'productTypeUsed',
  'preReinstatmentImage',
  'postReinstatmentImage',
];

const defaultValues = {
  siteId: '',
  siteDevName: '',
  blockName: '',
  siteLocation: '',
  reportStartDate: moment(),
  items: null,
  generalNotesAndComments: '',
  signatureImage: null,
};

const InspectionForm = (props: Props): React.Node => {
  const { onSubmit } = props;
  const submitRef = React.useRef();
  const location = useLocation();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [expanded, setExpanded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);

  const maxSteps = steps.length;

  React.useEffect(() => {
    if (location) {
      setValue('siteLocation', location);
    }
  }, [location, setValue]);

  const handleChange =
    (panel: string) => (event: SyntheticEvent<*>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleNext = async () => {
    if (activeStep === 2) {
      submitRef.current.click();

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setHasError(false);
  };

  const handleOnSubmit = async (data) => {
    const allImages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data.items) {
      let helper = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key of IMAGE_FIELDS) {
        // eslint-disable-next-line no-restricted-syntax
        for (const image of item[key]) {
          let imageData = { fileName: null, base64: null };

          // eslint-disable-next-line no-await-in-loop
          imageData = await convertFileToBase64(image, true);
          helper.push(imageData);
          allImages.push(imageData);
        }
        item[key] = helper;

        helper = [];
      }
    }

    onSubmit({
      ...data,
      allImages,
      signature: [{ fileName: 'signature', base64: data.signatureImage }],
    });
  };

  const handleOnError = () => setHasError(true);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert severity="error">
          There is a problem. Please, check all required fields.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 50,
            bgcolor: 'background.default',
            paddingTop: '5px',
            position: 'fixed',
            top: 56,
            zIndex: 1,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>

          <Typography sx={{ fontSize: '10px' }}>
            {`Page ${activeStep + 1} / ${maxSteps}`}
          </Typography>
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '60px  10px 50px 10px ',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ display: activeStep === 0 ? 'auto' : 'none' }}>
              <FieldContainer
                htmlFor="siteId"
                label="Site ID"
                error={errors.siteId}
              >
                <Controller
                  name="siteId"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" fullWidth />
                  )}
                />
              </FieldContainer>

              <FieldContainer
                htmlFor="siteDevName"
                label="Site Development Name"
                error={errors.siteDevName}
              >
                <Controller
                  name="siteDevName"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" fullWidth />
                  )}
                />
              </FieldContainer>

              <FieldContainer
                htmlFor="blockName"
                label="Block Name"
                error={errors.blockName}
              >
                <Controller
                  name="blockName"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" fullWidth />
                  )}
                />
              </FieldContainer>

              <FieldContainer
                htmlFor="siteLocation"
                label="Site Location"
                error={errors.siteLocation}
              >
                <Controller
                  name="siteLocation"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" fullWidth />
                  )}
                />
              </FieldContainer>

              <FieldContainer
                htmlFor="reportStartDate"
                label="Report start date"
                error={errors.reportStartDate}
              >
                <Controller
                  name="reportStartDate"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...rest } }) => (
                    <MobileDatePicker
                      id="reportStartDate-dialog"
                      inputFormat="MM/DD/YYYY"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      {...rest}
                    />
                  )}
                />
              </FieldContainer>
            </Box>

            <Box sx={{ display: activeStep === 1 ? 'auto' : 'none' }}>
              {items.map((item, index) => (
                <Accordion
                  expanded={expanded === `item${item}`}
                  onChange={handleChange(`item${item}`)}
                  sx={{ backgroundColor: 'transparent' }}
                  key={`item_${item}`}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{ backgroundColor: '#dfdfdf' }}
                  >
                    <Typography>Item #{item}</Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <FieldContainer
                      htmlFor={`items.${index}.operativeName`}
                      label="Operative name"
                      error={errors.items && errors.items[index]?.operativeName}
                    >
                      <Controller
                        name={`items.${index}.operativeName`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} variant="outlined" fullWidth />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.reIntatementDate`}
                      label="Re-Intatement date"
                      error={
                        errors.items && errors.items[index]?.reIntatementDate
                      }
                    >
                      <Controller
                        name={`items.${index}.reIntatementDate`}
                        control={control}
                        defaultValue=""
                        render={({ field: { ref, ...rest } }) => (
                          <MobileDatePicker
                            inputFormat="MM/DD/YYYY"
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            {...rest}
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.locationOfFireStopping`}
                      label="Location of Fire Stopping"
                      error={
                        errors.items &&
                        errors.items[index]?.locationOfFireStopping
                      }
                    >
                      <Controller
                        control={control}
                        name={`items.${index}.locationOfFireStopping`}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="Riser Cupboard"
                              control={<Radio />}
                              label="Riser Cupboard"
                            />

                            <FormControlLabel
                              value="Communal Corridor fire break"
                              control={<Radio />}
                              label="Communal Corridor fire break"
                            />

                            <FormControlLabel
                              value="Intake Room"
                              control={<Radio />}
                              label="Intake Room"
                            />

                            <FormControlLabel
                              value="Car Park Area"
                              control={<Radio />}
                              label="Car Park Area"
                            />

                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />

                            {getValues(
                              `items.${index}.locationOfFireStopping`,
                            ) === 'Other' && (
                              <Controller
                                name={`items.${index}.locationOfFireStopping.other`}
                                control={control}
                                render={({ field: otherField }) => (
                                  <TextField
                                    {...otherField}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          </RadioGroup>
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.floorLevel`}
                      label="Floor Level"
                      error={errors.items && errors.items[index]?.floorLevel}
                    >
                      <Controller
                        name={`items.${index}.floorLevel`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} variant="outlined" fullWidth />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.substrateType`}
                      label="Substrate Type"
                      error={errors.items && errors.items[index]?.substrateType}
                    >
                      <Controller
                        control={control}
                        name={`items.${index}.substrateType`}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="Solid Wall"
                              control={<Radio />}
                              label="Solid Wall"
                            />

                            <FormControlLabel
                              value="Solid Floor"
                              control={<Radio />}
                              label="Solid Floor"
                            />

                            <FormControlLabel
                              value="Plasterboard partition Wall"
                              control={<Radio />}
                              label="Plasterboard partition Wall"
                            />

                            <FormControlLabel
                              value="Plasterboard partition Void"
                              control={<Radio />}
                              label="Plasterboard partition Void"
                            />

                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />

                            {getValues(`items.${index}.substrateType`) ===
                              'Other' && (
                              <Controller
                                name={`items.${index}.substrateType.other`}
                                control={control}
                                render={({ field: otherField }) => (
                                  <TextField
                                    {...otherField}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          </RadioGroup>
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.substrateDepth`}
                      label="Substrate Depth"
                      error={
                        errors.items && errors.items[index]?.substrateDepth
                      }
                    >
                      <Controller
                        name={`items.${index}.substrateDepth`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.serviceType`}
                      label="Service Type"
                      error={errors.items && errors.items[index]?.serviceType}
                    >
                      <Controller
                        control={control}
                        name={`items.${index}.serviceType`}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="Single Cable Cat5e"
                              control={<Radio />}
                              label="Single Cable Cat5e"
                            />

                            <FormControlLabel
                              value="Cable Bundle Cat5e"
                              control={<Radio />}
                              label="Cable Bundle Cat5e"
                            />

                            <FormControlLabel
                              value="Single Cable Fibre"
                              control={<Radio />}
                              label="Single Cable Fibre"
                            />

                            <FormControlLabel
                              value="Cable Bundle Fibre"
                              control={<Radio />}
                              label="Cable Bundle Fibre"
                            />

                            <FormControlLabel
                              value="Invisilight Cnduit & Fibre Drop Cable"
                              control={<Radio />}
                              label="Invisilight Cnduit & Fibre Drop Cable"
                            />

                            <FormControlLabel
                              value="Invisilight Cnduit"
                              control={<Radio />}
                              label="Invisilight Cnduit"
                            />

                            <FormControlLabel
                              value="Invisilight Coridor Cable"
                              control={<Radio />}
                              label="Invisilight Coridor Cable"
                            />

                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />

                            {getValues(`items.${index}.serviceType`) ===
                              'Other' && (
                              <Controller
                                name={`items.${index}.serviceType.other`}
                                control={control}
                                render={({ field: otherField }) => (
                                  <TextField
                                    {...otherField}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          </RadioGroup>
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.holeDiameter`}
                      label="Hole Diameter"
                      error={errors.items && errors.items[index]?.holeDiameter}
                    >
                      <Controller
                        name={`items.${index}.holeDiameter`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.bundle`}
                      label="Cable Bundle/Single Cable Diameter/ Services Diameter ( Record diameter in mm)"
                      error={errors.items && errors.items[index]?.bundle}
                    >
                      <Controller
                        name={`items.${index}.bundle`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.productTypeUsed`}
                      label="Product Type Used"
                      error={
                        errors.items && errors.items[index]?.productTypeUsed
                      }
                    >
                      <Controller
                        name={`items.${index}.productTypeUsed`}
                        control={control}
                        render={({ field }) => (
                          <input
                            type="file"
                            onChange={(e) => {
                              field.onChange(e.target.files);
                            }}
                            multiple
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.intendedProductResistanceTime`}
                      label="Intended Product Resistance Time"
                      error={
                        errors.items &&
                        errors.items[index]?.intendedProductResistanceTime
                      }
                    >
                      <Controller
                        control={control}
                        name={`items.${index}.intendedProductResistanceTime`}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="30 Min"
                              control={<Radio />}
                              label="30 Min"
                            />

                            <FormControlLabel
                              value="60 Min"
                              control={<Radio />}
                              label="60 Min"
                            />

                            <FormControlLabel
                              value="90 Min"
                              control={<Radio />}
                              label="90 Min"
                            />

                            <FormControlLabel
                              value="120 Min"
                              control={<Radio />}
                              label="120 Min"
                            />

                            <FormControlLabel
                              value="240 Min"
                              control={<Radio />}
                              label="240 Min"
                            />
                          </RadioGroup>
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.preReinstatmentImage`}
                      label="Pre Reinstatment Image"
                      error={
                        errors.items &&
                        errors.items[index]?.preReinstatmentImage
                      }
                    >
                      <Controller
                        name={`items.${index}.preReinstatmentImage`}
                        control={control}
                        render={({ field }) => (
                          <input
                            type="file"
                            onChange={(e) => {
                              field.onChange(e.target.files);
                            }}
                            multiple
                          />
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer
                      htmlFor={`items.${index}.postReinstatmentImage`}
                      label="Post Reinstatment Image"
                      error={
                        errors.items &&
                        errors.items[index]?.postReinstatmentImage
                      }
                    >
                      <Controller
                        name={`items.${index}.postReinstatmentImage`}
                        control={control}
                        render={({ field }) => (
                          <input
                            type="file"
                            onChange={(e) => {
                              field.onChange(e.target.files);
                            }}
                            multiple
                          />
                        )}
                      />
                    </FieldContainer>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Button onClick={() => setItems([...items, items.length + 1])}>
                Add Item +
              </Button>
            </Box>

            <Box sx={{ display: activeStep === 2 ? 'auto' : 'none' }}>
              <FieldContainer
                htmlFor="generalNotesAndComments"
                label="General Notes and Comments"
                error={errors.generalNotesAndComments}
              >
                <Controller
                  render={({ field }) => (
                    <InputBase
                      {...field}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows="3"
                    />
                  )}
                  name="generalNotesAndComments"
                  control={control}
                />
              </FieldContainer>

              <SignatureFormField
                label="Operative Signature"
                value={getValues('signatureImage')}
                onChange={(val) => {
                  setValue('signatureImage', val);
                  forceUpdate();
                }}
                error={errors.signatureImage}
              />

              <Button type="submit" sx={{ display: 'none' }} ref={submitRef} />
            </Box>
          </LocalizationProvider>
        </Box>

        <MobileStepper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              {activeStep === maxSteps - 1 ? 'Finish' : 'Next'}

              {activeStep < maxSteps - 1 && <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </form>
  );
};

export default InspectionForm;
