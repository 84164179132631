// @flow
import * as React from 'react';

import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import { Logout as LogoutIcon, Menu as MenuIcon } from '@mui/icons-material';
import AA from 'Components/AA';
import { MENU } from 'Constants';
import { useUserMe } from 'Entities/User';
import { useAuth } from 'Hooks/useAuth';
import { Link, Outlet, useLocation } from 'react-router-dom';

const drawerWidth = 240;

type Props = {|
  window: ?Object,
|};

function Layout(props: Props): React.Node {
  const { logout } = useAuth();
  const [{ data: user }] = useUserMe();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar>
        <Link to="/" onClick={handleDrawerToggle}>
          <img src="/Venidi.png" height="54px" alt="Venidi" />
        </Link>
      </Toolbar>

      <Divider />

      <List>
        {user &&
          MENU.map((button) => (
            <AA key={button.title} roles={button.roles}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleDrawerToggle}
                  component={Link}
                  to={button.link}
                >
                  <ListItemText primary={button.title} />
                </ListItemButton>
              </ListItem>
            </AA>
          ))}

        <ListItem
          key="Logout"
          disablePadding
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          <ListItemButton
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
            component={Link}
            to="/login"
          >
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  let title = 'Venidi';
  const { pathname } = location;

  if (pathname !== '/') {
    title = MENU.find((el) => el.link === location.pathname)?.title || 'Venidi';
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          <IconButton
            color="inherit"
            edge="end"
            onClick={() => logout()}
            sx={{ ml: 2, display: { sm: 'none' } }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
        }}
      >
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
