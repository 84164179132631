// @flow
/* eslint-disable react/no-array-index-key, import/no-cycle */

import * as React from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress,
  // $FlowFixMe
} from '@mui/material';

import { TableHeader } from '.';
import type { Action, Column } from './Types';

// eslint-disable react/require-default-props
type Props = {
  columns: Column[],
  actions: Action[],
  items: any[],
  loading: boolean,
};

export default function DesktopTable({
  columns,
  actions,
  items,
  loading,
}: Props) {
  return (
    <Table>
      <TableHeader columns={columns} actions={actions} />

      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </TableCell>
          </TableRow>
        )}

        {!loading &&
          items &&
          items.map((item, index) => (
            <TableRow key={index}>
              {columns.map((itm, idx) => {
                let val;

                if (itm.key.indexOf('.') > -1) {
                  const keys = itm.key.split('.');

                  keys.forEach((key) => {
                    if (val) {
                      val = val[key];
                    } else {
                      val = item[key];
                    }
                  });
                } else {
                  val = item[itm.key];
                }

                return (
                  <TableCell key={idx}>
                    {itm.component ? itm.component(val, item) : val}
                  </TableCell>
                );
              })}

              {actions.length > 0 && (
                <TableCell>
                  {actions.map((actionComponent, idx): any =>
                    actionComponent(idx, item),
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
