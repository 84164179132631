// @flow
import * as React from 'react';

import { Box, Button, LinearProgress } from '@mui/material';

import InspectionFrom from 'Components/InspectionForm';
// $FlowFixMe
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FireReportDocument } from 'Components/DocumentPDF';

function FireReport(): React.Node {
  const [formData, setFormData] = React.useState(null);
  const download = React.useRef(false);

  React.useEffect(() => {
    if (!formData) {
      download.current = null;
    }
  }, [formData]);

  if (!formData) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <InspectionFrom
          onSubmit={async (data) => {
            setFormData(data);
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <PDFDownloadLink document={<FireReportDocument data={formData} />}>
        {({ blob, loading }) => {
          if (blob && !loading && !download.current) {
            setTimeout(() => {
              if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveOrOpenBlob(
                  download.current,
                  `${formData.siteId}.pdf`,
                );
              } else {
                // 2. Create blob link to download
                const link = document.createElement('a');

                link.href = window.URL.createObjectURL(
                  new Blob([download.current], {
                    type: 'application/pdf',
                  }),
                );
                link.setAttribute('download', `${formData.siteId}.pdf`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
              }
            }, 1000);
          }

          download.current = blob;

          return loading && <LinearProgress />;
        }}
      </PDFDownloadLink>

      <Button
        onClick={() => setFormData(null)}
        variant="contained"
        sx={{
          m: 2,
        }}
      >
        New
      </Button>
    </>
  );
}

export default FireReport;
