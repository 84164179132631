// @flow
/* eslint-disable react/no-array-index-key, import/no-cycle */

import {
  Box,
  CircularProgress,
  Table as MUITable,
  styled,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import React from 'react';
import type { Action, Column } from './Types';

// eslint-disable react/require-default-props
type Props = {
  columns: Column[],
  actions: Action[],
  items: any[],
  loading: boolean,
};

const Table = styled(MUITable)(() => ({
  '&:nth-of-type(odd)': {
    background: '#CCC',
  },
}));

export default function MobileTable({
  columns,
  actions,
  items,
  loading,
}: Props): React.Node {
  if (loading) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return items.map((item, index) => (
    <Table key={index}>
      <TableBody>
        {columns.map((itm) => {
          let val = item[itm.key];

          if (itm.key.indexOf('.') > -1) {
            const keys = itm.key.split('.');

            keys.forEach((key) => {
              if (val) {
                val = val[key];
              } else {
                val = item[key];
              }
            });
          } else {
            val = item[itm.key];
          }

          return (
            <TableRow key={`${itm.title}_${index}`}>
              <TableCell>{itm.title}</TableCell>

              <TableCell>
                {itm.component ? itm.component(val, item) : val}
              </TableCell>
            </TableRow>
          );
        })}

        {actions.length > 0 && (
          <TableRow>
            <TableCell>Actions</TableCell>

            <TableCell>
              {actions.map((actionComponent, idx): any =>
                actionComponent(idx, item),
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ));
}
