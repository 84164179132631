// @flow
import * as React from 'react';

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    padding: '20px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
  },
  title: {
    fontSize: '14px',
    fontWeight: '800',
  },
  image: {
    width: '92px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  imageTitle: {
    fontSize: 8,
  },
});

type Props = {|
  title: string,
  image: string,
|};

const TableRow2ImageBase64 = ({ title, image }: Props): React.Node => (
  <View style={styles.wrapper}>
    {title && <Text style={styles.title}>{title}</Text>}

    <View style={styles.row}>
      <View style={styles.column}>
        <Image src={image} style={styles.image} />
      </View>
    </View>
  </View>
);

export default TableRow2ImageBase64;
