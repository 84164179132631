// @flow
import * as React from 'react';

import { Box } from '@mui/material';

import DraForm from 'Components/DraForm';
import { useDraCreate } from 'Entities/Dra';
import { useNavigate } from 'react-router-dom';

function Dra(): React.Node {
  const [resp, draCreate] = useDraCreate();
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <DraForm
        loading={resp.loading}
        onSubmit={async (data) => {
          const response = await draCreate({
            data,
          });

          if (response.status === 201) {
            navigate('/dra');
          }
        }}
      />
    </Box>
  );
}

export default Dra;
