// @flow

import type { AxiosCommonData } from '../../@Axios/useAxios';
import useAxios from '../../@Axios/useAxios';

export type User = {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
  roles?: string[],
};

type UserMe = [
  {
    ...$Exact<AxiosCommonData>,
    data: User,
  },
  Function,
  Function,
];

export const useUserMe = (options: Object = {}): UserMe =>
  useAxios(
    {
      url: '/me',
      method: 'get',
    },
    options,
  );

export type UserLoginToken = {
  token: string,
};

type UserLogin = [
  {
    ...$Exact<AxiosCommonData>,
    data: UserLoginToken,
  },
  Function,
  Function,
];

export const useUserLogin = (): UserLogin =>
  useAxios(
    {
      url: '/login_check',
      method: 'post',
    },
    {
      manual: true,
    },
  );

type UserList = [
  {
    ...$Exact<AxiosCommonData>,
    data: User,
  },
  Function,
  Function,
];

export const useUserList = (): UserList =>
  useAxios({
    url: '/user',
    method: 'get',
  });

export default {
  useUserLogin,
  useUserMe,
};
