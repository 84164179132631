// @flow
import { Box, Button, Typography } from '@mui/material';
import AA from 'Components/AA';
import { MENU } from 'Constants';
import { useUserMe } from 'Entities/User';
import * as React from 'react';
import { Link } from 'react-router-dom';

function Dashboard(): React.Node {
  const [{ data }] = useUserMe();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {data && (
        <Typography sx={{ m: 2 }} variant="h5" gutterBottom>
          {`Welcome, ${data.firstName} ${data.lastName}`}
        </Typography>
      )}

      {MENU.map((menu) => (
        <AA key={menu.title} roles={menu.roles}>
          <Button
            component={Link}
            to={menu.link}
            variant="contained"
            sx={{
              m: 2,
            }}
          >
            {menu.title}
          </Button>
        </AA>
      ))}
    </Box>
  );
}

export default Dashboard;
