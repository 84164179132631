// @flow
import { ROLES } from 'Constants';
import { useUserMe } from 'Entities/User';
import React from 'react';
import { useAuth } from '../../Hooks/useAuth';

export default function AA({
  children,
  roles = [ROLES.User],
}: {
  children: any,
  roles: ?(string[]),
}): React.Component {
  const { logout } = useAuth();
  const [{ data: user, loading, error }] = useUserMe();

  if (loading) {
    return null;
  }

  if ((error && error.includes('401')) || !user) {
    logout();
  }

  if (user.roles.some((v) => roles.includes(v))) {
    return children;
  }

  return null;
}
