// @flow

import type { AxiosCommonData } from '../../@Axios/useAxios';
import useAxios from '../../@Axios/useAxios';

export type Dra = {|
  id: number,
  address: string,
  date: string,
  accessAndEgress: boolean,
  loadingUnloading: boolean,
  manualHandling: boolean,
  workingAtHeight: boolean,
  liftingAndLowering: boolean,
  workingWithCableOrAntenna: boolean,
  useOfPowerTools: boolean,
  workingWithElectricity: boolean,
  accessingFalseFloorsOrCeilings: boolean,
  hotWorks: boolean,
  confinedSpaceWorking: boolean,
  loneWorking: boolean,
  surveying: boolean,
  testingAndCommissioning: boolean,
  interactionWithOthers: boolean,
  electricalTest: boolean,
  undergroundUtilityServices: boolean,
  permitToDig: boolean,
  excavation: boolean,
  stockpiles: boolean,
  reinstatement: boolean,
  qualityChecks: boolean,
  siteLeftSafe: boolean,
  haveYouReadAsbestosReport: boolean,
  haveYouReceivedADailyActivitySiteBrief: boolean,
  isClimbingRequired: boolean,
  isCivilsRequired: boolean,
  preUseInspection: boolean,
  rating: string,
  fillBy: {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
  },
  sign: string,
  createdAt: string,
|};

type DraResponse = [
  {
    ...$Exact<AxiosCommonData>,
    data: Dra,
  },
  Function,
  Function,
];

export const useDraCreate = (): DraResponse =>
  useAxios(
    {
      url: '/dra',
      method: 'post',
    },
    {
      manual: true,
    },
  );

export const useDraView = (): DraResponse => {
  const [response, fetch, clear] = useAxios(
    {
      method: 'get',
    },
    {
      manual: true,
    },
  );

  const getDra = (data) => {
    const { id } = data;

    fetch({ url: `/dra/${id}` });
  };

  return [response, getDra, clear];
};

type DraList = [
  {
    ...$Exact<AxiosCommonData>,
    data: {
      result: Array<Dra>,
      meta: {
        total: number,
        page: number,
        limit: number,
        totalPages: number,
      },
    },
  },
  Function,
  Function,
];

export const useDraList = (): DraList =>
  useAxios({
    url: '/dra',
    method: 'get',
  });

export default {
  useDraCreate,
  useDraList,
  useDraView,
};
