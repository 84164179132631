// @flow
import * as React from 'react';

import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DraDocument from 'Components/DocumentPDF/DraDocument';
import { DRA_FIELDS } from 'Constants';
import { useDraView } from 'Entities/Dra';
import { Link as RouterLink, useParams } from 'react-router-dom';

function DraView(): React.Node {
  const { id } = useParams();
  const [{ data, loading }, refetch] = useDraView();

  console.info(data);
  React.useEffect(() => {
    if (id && !data) {
      refetch({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  return (
    <Box sx={{ p: { xs: 1, sm: 3 } }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Link component={RouterLink} to="/dra" sx={{ mr: 2 }}>
          Back to list
        </Link>

        {data && (
          <PDFDownloadLink
            document={<DraDocument data={data} />}
            fileName={`Venidi_DRA_${data.id}`}
            style={{
              color: '#1976d2',
              textDecoration: 'underline',
              textDecorationColor: 'rgba(25, 118, 210, 0.4)',
            }}
          >
            PDF
          </PDFDownloadLink>
        )}
      </Box>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mt: 1 }}>
        {loading && (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        {data && (
          <Table>
            <TableBody>
              {Object.keys(data).map((itm) => {
                let val = data[itm];

                if (itm === 'fillBy') {
                  val = `${val.firstName} ${val.lastName}`;
                }

                if (typeof val === 'boolean') {
                  val = val ? 'Yes' : 'No';
                }

                if (itm === 'sign') {
                  val = (
                    <img src={val} alt="sign" style={{ maxWidth: '50%' }} />
                  );
                }

                if (itm === 'date') {
                  const date = new Date(val);

                  val = date.toLocaleString();
                }

                return (
                  <TableRow key={itm}>
                    <TableCell>{DRA_FIELDS[itm]}</TableCell>

                    <TableCell>{val}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Box>
  );
}

export default DraView;
