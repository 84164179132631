// @flow
import { Error as ErrorIcon } from '@mui/icons-material';
import { Alert, Box, CircularProgress } from '@mui/material';
import { ROLES } from 'Constants';
import { useUserMe } from 'Entities/User';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';

export default function ProtectedRoute({
  children,
  roles = [ROLES.User],
}: {
  children: any,
  roles: ?(string[]),
}): React.Component {
  const { logout } = useAuth();
  const [{ data: user, loading, error }] = useUserMe();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error && error.includes('401')) {
    logout();
  }

  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  if (user.roles.some((v) => roles.includes(v))) {
    return children;
  }

  return (
    <Box>
      <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
        You don't have permissions to see this page!
      </Alert>

      <Link to="/">Go to Dashboard</Link>
    </Box>
  );
}
