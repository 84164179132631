// @flow
import * as React from 'react';

import {
  Alert,
  CssBaseline,
  Card as MuiCard,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

import { createTheme, styled, ThemeProvider } from '@mui/material/styles';

import LoginForm from 'Components/LoginForm';
import { useUserLogin } from 'Entities/User';
import { useAuth } from 'Hooks/useAuth';

const defaultTheme = createTheme();

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

const SignInContainer = styled(Stack)(() => ({
  padding: 20,
  marginTop: '10vh',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
  },
}));

function Login(): React.Node {
  const { login } = useAuth();
  const [{ loading, error }, userLogin] = useUserLogin();
  const [hasError, setHasError] = React.useState(false);

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setHasError(false);
  };

  React.useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme />

      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert severity="error">Wrong email or password.</Alert>
      </Snackbar>

      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <img src="/Venidi.png" alt="Venidi" />

          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign in
          </Typography>

          <LoginForm
            onSubmit={async (data) => {
              const response = await userLogin({ data });

              login({
                email: data.username,
                refreshToken: response.data.refresh_token,
              });
            }}
            isLoading={loading}
          />
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}

export default Login;
