// @flow
import './App.css';

import * as React from 'react';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';

import Layout from 'Components/Layout';
import Dashboard from 'Routes/Dashboard';
import Dra from 'Routes/Dra';
import FireReport from 'Routes/FireReport';
import InstallationAsBuild from 'Routes/InstallationAsBuild';
import Login from 'Routes/Login';

import axiosSetup from '@Axios/axiosSetup';
import ProtectedRoute from 'Components/ProtectedRoute';
import { ROLES } from 'Constants';
import { AuthProvider } from 'Hooks/useAuth';
import DraList from 'Routes/DraList';
import DraView from 'Routes/DraView';

const router = createBrowserRouter([
  {
    path: '*',
    element: <Root />,
  },
]);

function Root() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/fire-report"
            element={
              <ProtectedRoute roles={[ROLES.Admin]}>
                <FireReport />
              </ProtectedRoute>
            }
          />

          <Route
            path="/iab"
            element={
              <ProtectedRoute roles={[ROLES.Admin]}>
                <InstallationAsBuild />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dra/create"
            element={
              <ProtectedRoute>
                <Dra />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dra"
            element={
              <ProtectedRoute>
                <DraList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dra/view/:id"
            element={
              <ProtectedRoute>
                <DraView />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </AuthProvider>
  );
}

function App(): React.Node {
  axiosSetup();

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
