// @flow
import * as React from 'react';
// $FlowFixMe
import {
  Button as MUIButton,
  List as MUIList,
  ListItem as MUIListItem,
  styled,
  // $FlowFixMe
} from '@mui/material';

// $FlowFixMe
import {
  NavigateNext as NextIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  NavigateBefore as BeforeIcon,
  // $FlowFixMe
} from '@mui/icons-material';

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  justifyItems: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: '0 auto',
  },
}));

const Spacer = styled('div')(({ theme }) => ({
  flex: '1 1 100%',
  [theme.breakpoints.down('sm')]: {
    flex: '0',
  },
}));

const List = styled(MUIList)(() => ({
  display: 'inline-flex',
}));

const ListItem = styled(MUIListItem)(() => ({
  display: 'inline-flex',
  padding: 0,
}));

const Button = styled(MUIButton)(() => ({
  textAlign: 'center',
  padding: 0,
  width: 36,
  minWidth: 36,
  height: 36,
  minHeight: 36,
  fontSize: 14,
}));

const ActiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const range = (start, end) => {
  const res = [];

  for (let i = start; i < end; i += 1) {
    res.push(i);
  }

  return res;
};

type Props = {
  current: number,
  total: number,
  onChange: (page: number) => void,
  visiblePage: number,
};

export default function Pager({
  current,
  total = 0,
  onChange,
  visiblePage = 5,
}: Props) {
  const visibleRange = () => {
    // $FlowFixMe
    if (total <= visiblePage) {
      return range(1, total + 1);
    }
    // $FlowFixMe
    const a = parseInt(visiblePage / 2, 10);
    let start = Math.max(current - a, 1);
    let end = start + visiblePage;

    if (end > total) {
      end = total + 1;
      // $FlowFixMe
      start = end - visiblePage;
    }

    return range(start, end);
  };

  const pages = visibleRange();

  if (pages.length <= 1) {
    return null;
  }

  return (
    <Nav>
      <Spacer />

      <List dense>
        <ListItem disableGutters>
          <Button onClick={() => onChange(1)}>
            <FirstPageIcon />
          </Button>
        </ListItem>

        <ListItem disableGutters>
          <Button
            onClick={() => onChange(current - 1)}
            disabled={current - 1 < 1}
          >
            <BeforeIcon />
          </Button>
        </ListItem>

        {pages.map((page) => (
          <ListItem key={page} disableGutters>
            {current === page ? (
              <ActiveButton onClick={() => onChange(page)}>{page}</ActiveButton>
            ) : (
              <Button onClick={() => onChange(page)}>{page}</Button>
            )}
          </ListItem>
        ))}

        <ListItem disableGutters>
          <Button
            onClick={() => onChange(current + 1)}
            disabled={current + 1 > total}
          >
            <NextIcon />
          </Button>
        </ListItem>

        <ListItem disableGutters>
          <Button onClick={() => onChange(total)}>
            <LastPageIcon />
          </Button>
        </ListItem>
      </List>

      <Spacer />
    </Nav>
  );
}
