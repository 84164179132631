/* eslint-disable func-names */
/* eslint-disable no-console */
// @flow

import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  TextField,
} from '@mui/material';
import * as React from 'react';

import FieldContainer from 'Components/FieldContainer';
import { Controller, useForm } from 'react-hook-form';
// $FlowFixMe
import * as yup from 'yup';

// $FlowFixMe
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

type Props = {|
  onSubmit: Function,
  isLoading: boolean,
|};

const LoginForm = (props: Props): React.Node => {
  const { onSubmit, isLoading } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [hasError, setHasError] = React.useState(false);

  const handleOnSubmit = async (data) => {
    onSubmit({
      ...data,
    });
  };

  const handleOnError = () => setHasError(true);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(handleOnSubmit, handleOnError)}
    >
      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        sx={{
          position: 'absolute',
          bottom: '50px',
        }}
      >
        <Alert severity="error">
          There is a problem. Please, check all required fields.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '10px',
          }}
        >
          <Box>
            <FieldContainer
              htmlFor="username"
              label="Email"
              error={errors.email}
            >
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField {...field} variant="outlined" fullWidth />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor="password"
              label="Passowrd"
              error={errors.password}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    fullWidth
                  />
                )}
              />
            </FieldContainer>

            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
