/* eslint-disable func-names */
/* eslint-disable no-console */
// @flow

import {
  Alert,
  Box,
  Button,
  MobileStepper,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import * as React from 'react';
// $FlowFixMe
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

// $FlowFixMe
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// $FlowFixMe
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useForm } from 'react-hook-form';

import useLocation from 'Hooks/useLocation';

// $FlowFixMe
import * as yup from 'yup';

import { convertFileToBase64 } from 'Utils/image';

import moment from 'moment';

// $FlowFixMe
import { yupResolver } from '@hookform/resolvers/yup';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

const schema = yup
  .object({
    siteId: yup.string().required(),
    siteName: yup.string().required(),
    siteLocation: yup.string().required(),
    reportStartDate: yup.object().required(),
    fiberProvider: yup.string().required(),
    numberOfUnits: yup.string().required(),
    cablingType: yup.string().required(),
    leadEngineerForBuild: yup.string().required(),
    endDateOfStageOneCablingWork: yup.object().required(),
    items: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          enclosureID: yup.string().required(),
          enclosure: yup.string().required(),
          closeUpImageOfSplitterWallBox: yup.mixed().nullable().required(),
          wideViewImageOfSplitterWallbox: yup.mixed().nullable().required(),
          cablingIsInstalled: yup.string().required(),
          closeUpImageOfCableRoutes: yup.mixed().nullable().required(),
          wideViewImageOfCableRoutes: yup.mixed().nullable().required(),
          cablingEntering: yup.string().required(),
          newOrExistingContainment: yup.string().required(),
          poeCspInstalled: yup.string().required(),
          imageOfPoeCsp: yup.mixed().nullable().required(),
        }),
      )
      .min(1),
    generalNotesAndComments: yup.string().nullable(),
    operativeSignature: yup.string().nullable().required(),
  })
  .required();

type Props = {|
  onSubmit: Function,
|};

const steps = [
  { label: 'Title Page' },
  { label: 'Build Summary' },
  { label: 'As Built Inventory Log' },
  { label: 'Signatories' },
];

const defaultValues = {
  siteId: '',
  siteName: '',
  siteLocation: '',
  reportStartDate: moment(),

  fiberProvider: '',
  numberOfUnits: '',
  cablingType: '',
  leadEngineerForBuild: '',
  endDateOfStageOneCablingWork: moment(),

  items: null,
  generalNotesAndComments: null,
  signatureImage: null,
};

const IMAGE_FIELDS = [
  'closeUpImageOfSplitterWallBox',
  'wideViewImageOfSplitterWallbox',
  'closeUpImageOfCableRoutes',
  'wideViewImageOfCableRoutes',
  'imageOfPoeCsp',
];

const InstallationAsBuildForm = (props: Props): React.Node => {
  const { onSubmit } = props;

  const submitRef = React.useRef();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);

  const maxSteps = steps.length;

  React.useEffect(() => {
    if (location) {
      setValue('siteLocation', location);
    }
  }, [location, setValue]);

  const handleNext = async () => {
    if (activeStep === 3) {
      submitRef.current.click();

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setHasError(false);
  };

  const handleOnSubmit = async (data) => {
    const allImages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data.items) {
      let helper = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key of IMAGE_FIELDS) {
        // eslint-disable-next-line no-restricted-syntax
        for (const image of item[key]) {
          let imageData = { fileName: null, base64: null };

          // eslint-disable-next-line no-await-in-loop
          imageData = await convertFileToBase64(image, true);
          helper.push(imageData);
          allImages.push(imageData);
        }
        item[key] = helper;
        helper = [];
      }
    }
    onSubmit({
      ...data,
      allImages,
      signature: [{ fileName: 'signature', base64: data.operativeSignature }],
    });
  };

  const handleOnError = () => setHasError(true);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert severity="error">
          There is a problem. Please, check all required fields.
        </Alert>
      </Snackbar>

      <Box sx={{ flexGrow: 1 }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 50,
            bgcolor: 'background.default',
            paddingTop: '5px',
            position: 'fixed',
            top: 56,
            zIndex: 1,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>

          <Typography sx={{ fontSize: '10px' }}>
            {`Page ${activeStep + 1} / ${maxSteps}`}
          </Typography>
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '60px  10px 50px 10px ',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Step1 activeStep={activeStep} control={control} errors={errors} />

            <Step2 activeStep={activeStep} control={control} errors={errors} />

            <Step3 activeStep={activeStep} control={control} errors={errors} />

            <Step4
              activeStep={activeStep}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
            />

            <Button type="submit" sx={{ display: 'none' }} ref={submitRef} />
          </LocalizationProvider>
        </Box>

        <MobileStepper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: { xs: 0, sm: 240 },
            right: 0,
          }}
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              {activeStep === maxSteps - 1 ? 'Finish' : 'Next'}

              {activeStep < maxSteps - 1 && <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </form>
  );
};

export default InstallationAsBuildForm;
