/* eslint-disable import/prefer-default-export */
export const ROLES = {
  User: 'ROLE_USER',
  Admin: 'ROLE_ADMIN',
};

export const MENU = [
  { title: 'New Fire report', link: '/fire-report', roles: [ROLES.Admin] },
  { title: 'New Instalation As Build', link: '/iab', roles: [ROLES.Admin] },
  { title: 'DRAs', link: '/dra', roles: [ROLES.User] },
];

export const DRA_FIELDS = {
  id: '#',
  address: 'Address',
  date: 'Date',
  accessAndEgress: 'Access & Egress',
  loadingUnloading: 'Loading/Unloading',
  manualHandling: 'Manual Handling',
  workingAtHeight: 'Working at Height',
  liftingAndLowering: 'Lifting & Lowering',
  workingWithCableOrAntenna: 'Working with Cable/Antenna Installation',
  useOfPowerTools: 'Use of Power Tools',
  workingWithElectricity: 'Working with Electricity',
  accessingFalseFloorsOrCeilings: 'Accessing false floors/ceilings',
  hotWorks: 'Hot Works',
  confinedSpaceWorking: 'Confined Space Working',
  loneWorking: 'Lone Working',
  surveying: 'Surveying',
  testingAndCommissioning: 'Testing & Commissioning',
  interactionWithOthers: 'Interaction with others',
  electricalTest: 'Electrical Test',
  undergroundUtilityServices: 'Underground utility services',
  permitToDig: 'Permit to Dig',
  excavation: 'Excavation',
  stockpiles: 'Stockpiles',
  reinstatement: 'Reinstatement',
  qualityChecks: 'Quality Checks',
  siteLeftSafe: 'Site Left Safe',
  haveYouReadAsbestosReport: 'Have you read the Asbestos report?',
  haveYouReceivedADailyActivitySiteBrief:
    'Have you received a daily activity site brief?',
  isClimbingRequired: 'Is climbing required',
  isCivilsRequired: 'Is civils required',
  preUseInspection: 'Pre-Use Inspection',
  rating: 'Rating',
  fillBy: 'User',
  sign: 'Sign',
  createdAt: 'Created at',
};
